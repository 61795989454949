@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
div:where(.swal2-container) h2:where(.swal2-title) {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .8em 1em 0;
  color: inherit;
  font-size: 1.875em;
  font-weight: 600;
  text-align: left !important;
  text-transform: none;
  word-wrap: break-word;
  font-family: monospace;
  color: #1a0101 !important;
}


div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #f55663 !important;
  color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
}


div:where(.swal2-container) .swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em .3em;
  padding: 0;
  overflow: auto;
  color: #ffffff !important;
  font-size: 1.13em;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  word-wrap: break-word;
  word-break: break-word;
  letter-spacing: 2px;
  line-height: 29px;
}
 */


 .lg\:min-h-screen {
  min-height: 92vh !important;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(42 118 162);
}





@media(max-width:1024px){
  #id{width:110%}
  }